<template>
  <div>
    <div class="content">
      <div class="prompt">
        <img src="../../assets/images/LTicon.png" alt="">
        <div class="promptText">字典管理</div>
      </div>
      <div class="searchContent">
        <div class="search">
          <div class="formList">
            <el-form :inline="true" :model="formInline" class="demo-form-inline">
              <el-form-item label="字典名称：">
                <el-input clearable v-model="formInline.dictName" placeholder="请输入字典名称" class="custom-input"></el-input>
              </el-form-item>
              <!-- <el-form-item label="字典类型：">
                <el-input v-model="formInline.dictName" placeholder="请输入字典类型" class="custom-input"></el-input>
              </el-form-item> -->
              <el-form-item label="字典状态：">
                <el-select clearable class="custom-select" :popper-append-to-body="false" v-model="formInline.state"
                  placeholder="请选择字典状态">
                  <!-- <el-option label="所有" value="2"></el-option> -->
                  <el-option label="启用" :value="0"></el-option>
                  <el-option label="停用" :value="1"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="开始时间：">
                <!-- <el-date-picker
                      v-model="timeValue"
                      value-format="yyyy-MM-dd"
                      type="daterange"
                      range-separator="至"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期">
                    </el-date-picker> -->
                <el-date-picker type="date" :append-to-body="false" placeholder="选择开始时间" value-format="yyyy-MM-dd"
                  class="custom-input" v-model="formInline.createdAtStart" style="width: 100%;"></el-date-picker>
              </el-form-item>
              <el-form-item label="结束时间：">
                <el-date-picker type="date" :append-to-body="false" placeholder="选择开始时间" :picker-options="pickerOptions"
                  value-format="yyyy-MM-dd" class="custom-input" v-model="formInline.createdAtEnd"
                  style="width: 100%;"></el-date-picker>
              </el-form-item>

              <el-form-item>
              </el-form-item>
            </el-form>
          </div>
          <div class="newView">
            <el-button type="primary" @click="onSubmit" icon="el-icon-search" class="custom-button">查询</el-button>
            <el-button type="primary" :class="isStringInArray('btnNewDictory') ? '' : 'btnShowAuthority'"
              @click="openViewDialog" icon="el-icon-plus" class="custom-button">新建字典</el-button>

            <!-- <el-button type="primary" @click="delTable('',0)" icon="el-icon-download" class="custom-button">导出</el-button> -->
          </div>
        </div>
      </div>
      <div class="table">
        <el-table v-loading="dataLoading" element-loading-text="加载中" element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.3)" :cell-style="{ color: '#AED6FF', fontSize: '12px' }"
          :header-cell-style="{ color: '#e8f4ff', fontSize: '14px' }" border :data="tableData" style="width: 100%"
          class="custom-table">
          <div slot="empty">
            <div v-if="tableData.length == 0">
              <div class="empty-data-view">
                <div class="tableText">
                  {{ dataLoading ? '' : '暂无数据' }}
                </div>
              </div>
            </div>
          </div>
          <el-table-column align="center" type="index" label="序号" :index="getIndex" width="100">

          </el-table-column>
          <el-table-column align="center" prop="dictName" label="字典名称" width="200">
            <!-- <template slot-scope="scope">
              <el-button type="text" @click="goDictionarySun(scope.row)">{{ scope.row.dictCode }}</el-button>
            </template> -->
          </el-table-column>
          <el-table-column align="center" prop="dictCode" label="字典编码" width="200">
            <template slot-scope="scope">
              <el-button type="text" @click="goDictionarySun(scope.row)">{{ scope.row.dictCode }}</el-button>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="feedbackTime" label="状态" width="150">
            <template slot-scope="scope">
              <div>{{ scope.row.state == 0 ? '启用' : scope.row.state == 1 ? '停用' : '-' }}</div>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="dictDesc" label="备注" :fluid="true" min-width="300">
            <template slot-scope="scope">
              <template v-if="isOverflow(scope.row.dictDesc)">
                <el-tooltip :content="scope.row.dictDesc" placement="top" :popper-class="'custom-tooltip'">
                  <div class="ellipsis">{{ scope.row.dictDesc }}</div>
                </el-tooltip>
              </template>
              <template v-else>
                <div class="ellipsis">{{ scope.row.dictDesc }}</div>
              </template>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="sort" label="排序" width="100">
          </el-table-column>
          <el-table-column align="center" prop="createTime" label="创建时间" width="200">
          </el-table-column>

          <el-table-column align="center" prop="data" fixed="right" label="操作" width="150">
            <template slot-scope="scope">
              <el-button :class="isStringInArray('btnEditDictory') ? '' : 'btnShowAuthority'" @click="replyDialog(scope.row)"
                type="text">编辑</el-button>
              <span :class="isStringInArray('btnEditDictory') ? '' : 'btnShowAuthority'">|</span>
              <el-button :class="isStringInArray('btnTableDictory') ? '' : 'btnShowAuthority'"
                @click="goDictionarySun(scope.row)" type="text">列表</el-button>
              <span :class="isStringInArray('btnTableDictory') ? '' : 'btnShowAuthority'">|</span>
              <el-button :class="isStringInArray('btnDeleteDictory') ? '' : 'btnShowAuthority'" @click="delTable(scope.row, 1)"
                type="text">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pagination">
        <div class="btn" @click="startHome">首页</div>
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageNum"
          :page-sizes="[10, 20, 30, 40]" :page-size="pageSize" layout="prev, pager, next, sizes,slot"
          :prev-text="prevButtonText" :next-text="nextButtonText" prev-button-class="custom-prev-button"
          next-button-class="custom-next-button" :total="total">
        </el-pagination>
        <div class="btn endBtn" @click="endHome">尾页</div>
        <div class="paginationEnd">
          <div>跳</div>至
          <el-input size="mini" @change="paginationEnd" class="paginationEndIpt" @input="handleEdit"
            v-model.number="paginationEndIpt"></el-input>
          <div>页</div>
          <div class="btn confirm" @click="jumpPage">确定</div>
        </div>
      </div>
    </div>

    <!-- 新建字典 -->
    <el-dialog @close="handleClose('ruleForm')" :title="updateType == 0 ? '新建字典' : '编辑字典'"
      :visible.sync="centerDialogVisible" width="30%">
      <div class="newCenter">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
          <el-form-item label="字典名称" prop="dictName">
            <el-input v-model.trim="ruleForm.dictName"></el-input>
          </el-form-item>
          <el-form-item label="字典编码" prop="dictCode">
            <el-input v-model.trim="ruleForm.dictCode"></el-input>
          </el-form-item>
          <el-form-item label="排序" prop="sort">
            <el-input v-model.trim="ruleForm.sort"></el-input>
          </el-form-item>
          <el-form-item label="状态" prop="state">
            <el-radio-group v-model="ruleForm.state">
              <el-radio :label="0">启用</el-radio>
              <el-radio :label="1">停用</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="备注" prop="">
            <el-input type="textarea" show-word-limit maxlength="200" v-model.trim="ruleForm.dictDesc"></el-input>
          </el-form-item>
        </el-form>

      </div>
      <span slot="footer" class="dialog-footer">
        <!-- <div class="">取消</div>
          <div class="">确定</div> -->
        <el-button class="dialogBtn" @click="cancelDialog">取 消</el-button>
        <el-button class="dialogBtn" type="primary" @click="submitForm('ruleForm')">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog :title="dialogText == 1 ? '删除' : '下载'" :visible.sync="replyDialogVisible" width="20%">
      <div class="deleteCenter">
        {{ dialogText == 1 ? '确定删除该条信息?' : '确定下载该文件?' }}
      </div>

      <span slot="footer" class="dialog-footer">
        <!-- <div class="">取消</div>
    <div class="">确定</div> -->
        <el-button class="dialogBtn" @click="replyDialogVisible = false">取 消</el-button>
        <el-button class="dialogBtn" type="primary" @click="replyDialogDelete">确 定</el-button>
      </span>
    </el-dialog>


  </div>
</template>

<script>
// import demo from "./demo.vue"
import { operation, operationAdd, operationId, operationUpdate, operationDelete } from '../../api/dailyWork.js'
export default {
  name: 'dictionary',
  data() {
    return {
      dialogText: 0,
      formInline: {
        dictLevel: 1,
        dictName: '',//名称
        dictName: '',//字典类型名称 
        state: '',//状态
        createdAtStart: null,//开始时间
        createdAtEnd: null,//结束时间

      },
      tableData: [],
      centerDialogVisible: false,
      replyDialogVisible: false,
      prevButtonText: '上一页',
      nextButtonText: '下一页',
      paginationEndIpt: '',
      pageSize: 10,
      pageNum: 1,
      total: 0,
      ruleForm: {
        dictName: '',
        dictCode: '',
        state: '',
        dictDesc: '',
        dictLevel: 1,
        sort: '',
        deleteFlage: 0,
      },

      id: '',
      updateType: 0,
      dataLoading: false,

      rules: {
        dictName: [
          { required: true, message: '请输入字典名称', trigger: 'blur' },
        ],
        dictCode: [
          { required: true, message: '请输入字典类型', trigger: 'blur' },
        ],
        sort: [
          { required: true, message: '请输入排序', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              if (/^(?=.*[0-9])\d*$/.test(value)) {
                callback();
              } else {
                callback(new Error("请输入包含0的正整数"));
              }
            },
            trigger: "blur"
          }
        ],
        state: [
          { required: true, message: '请选择状态', trigger: 'change' },
        ],
        authorityListBtn: [],

      }

    };
  },
  // components:{demo},

  mounted() {

  },
  created() {
    this.$store.commit('increment', 'Eios控制面板')
    this.$store.commit('selectChild', '字典管理')
    this.$store.commit("selectChildren", "");
    this.searchList()
    this.authorityListBtn = sessionStorage.getItem('roleInfo')
  },
  computed: {
    pickerOptions() {
      const self = this;
      return {
        disabledDate(time) {
          const limitDate = new Date(self.formInline.createdAtStart);
          limitDate.setHours(0, 0, 0, 0); // 重置时间为当天的开始时间

          return time.getTime() < limitDate.getTime();
        }
      };
    }
  },
  watch: {
    // 如果只需要监听对象中的一个属性值，则可以做以下优化：使用字符串的形式监听对象属性：
    "pageNum": {
      // 执行方法
      handler(newValue, oldValue) {
        this.paginationEndIpt = newValue;
      },
      immediate: true, // 第一次改变就执行
    },
  },

  methods: {
    //按钮权限
    isStringInArray(str) {
      if (this.authorityListBtn) {
        let list = this.authorityListBtn.includes(str);
        return list
      } else {
        return false
      }
    },
    isOverflow(content) {
      if(content){

        const lineHeight = 16;
        const numLines = Math.ceil(content.length / 42); // 每行大概显示42个字符
        // console.log("====>",numLines);
        return numLines >= 3;
      }else{
        return true
      }
    },
    //序号
    getIndex(index) {
      return (this.pageNum - 1) * this.pageSize + index + 1;
    },
    //关闭弹窗
    handleClose(ruleForm) {
      this.ruleForm = this.$options.data().ruleForm
      this.$refs[ruleForm].resetFields();
    },
    //取消
    cancelDialog() {
      this.centerDialogVisible = false
      this.ruleForm = this.$options.data().ruleForm

    },
    // 列表数据查询
    searchList() {
      this.dataLoading = true

      let obj = {
        ...this.formInline,
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }
      operation(obj).then(res => {
        this.dataLoading = false
        console.log(res);
        if (res.code == 200) {
          console.log(res.data);
          this.tableData = res.data
          this.total = res.total
        } else {
          this.$message({
            message: res.message,
            type: 'error',
            duration: 3000,
            customClass: "messageText",
          });
        }
      })
    },
    //删除
    replyDialogDelete() {

      operationDelete(this.id).then(res => {
        if (res.code == 200) {
          this.replyDialogVisible = false
          this.searchList()
          this.$message({
            message: res.message,
            type: 'success',
            duration: 3000,
            customClass: "messageText",
          });
        } else {
          this.$message({
            message: res.message,
            type: 'error',
            duration: 3000,
            customClass: "messageText",
          });
        }
      })

    },
    //编辑
    replyDialog(el) {
      this.updateType = 1
      this.id = el.id
      operationId(this.id).then(res => {
        if (res.code == 200) {
          this.ruleForm.dictName = res.data.dictName
          this.ruleForm.dictCode = res.data.dictCode
          this.ruleForm.state = res.data.state
          this.ruleForm.dictDesc = res.data.dictDesc
          this.ruleForm.sort = res.data.sort
          this.centerDialogVisible = true
        } else {
          this.$message({
            message: res.message,
            type: 'error',
            duration: 3000,
            customClass: "messageText",
          });
        }
      })
    },
    //删除
    delTable(el, type) {
      this.dialogText = type
      if (el) {
        this.id = el.id
      }
      this.replyDialogVisible = true
    },
    //查询列表
    onSubmit() {
      console.log('submit!');
      console.log(this.formInline);
      this.searchList()
    },

    //打开意见弹窗
    openViewDialog() {
      this.updateType = 0
      this.centerDialogVisible = true
    },
    //分页相关function
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.pageSize = val
      this.searchList()
      this.paginationEnd()
    },
    handleCurrentChange(val) {
      this.pageNum = val
      this.paginationEndIpt = val
      // console.log(`当前页: ${val}`);
      // console.log(this.pageNum);
      this.searchList()
    },
    //首页
    startHome() {
      this.pageNum = 1
      this.searchList()

    },
    //尾页
    endHome() {
      const lastPage = Math.ceil(this.total / this.pageSize);
      this.pageNum = lastPage;
      this.searchList()
    },
    handleEdit(e) {
      let value = e.replace(/[^\d]/g, ""); // 只能输入数字
      value = value.replace(/^0+(\d)/, "$1"); // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
      this.paginationEndIpt = value
    },
    //页数输入时
    paginationEnd() {
      const lastPage = Math.ceil(this.total / this.pageSize);
      if (this.paginationEndIpt < 1) {
        this.paginationEndIpt = 1
      } else {
        this.paginationEndIpt = this.paginationEndIpt < lastPage ? this.paginationEndIpt : lastPage
      }
    },
    //跳转到xx页
    jumpPage() {
      this.pageNum = this.paginationEndIpt
      // this.paginationEndIpt=''
      this.searchList()
    },


    //新建字典弹窗表单提交
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let obj = {
            ...this.ruleForm,
            id: this.id
          }
          let func = this.updateType == 0 ? operationAdd(this.ruleForm) : operationUpdate(obj)
          //console.log("111111111111");
          // console.log(this.ruleForm);
          // operationAdd(this.ruleForm)
          func.then(res => {
            if (res.code == 200) {
              this.centerDialogVisible = false
              this.$message({
                message: res.message,
                type: 'success',
                duration: 3000,
                customClass: "messageText",
              });
              this.$refs.ruleForm.resetFields();
              this.searchList()

            } else {
              this.$message({
                message: res.message,
                type: 'error',
                duration: 3000,
                customClass: "messageText",
              });

            }
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    //字典名称跳转
    goDictionarySun(el) {
      console.log(el);
      this.$router.push({
        name: 'DictionarySun',
        query: { dictCode: el.dictCode }

      })

    }



  },
};
</script >
<style src="@/utils/style/table.css" scoped></style>
<style scoped>
/* import */
.content {
  min-height: 600px;
  width: 100%;
  background: url(../../assets/images/rightBG.png);
  background-repeat: no-repeat;
  background-position: left left;
  background-size: cover;
  font-size: 16px;
  overflow: auto;
  padding-bottom: 20px;
}

.prompt {
  padding: 28px 0 0 17px;
  font-size: 16px;
  color: #ebf4ff;
  display: flex;
  align-items: center;
}

.promptText {
  margin-left: 10px;
}

/* .el-input>>>.el-input__inner {
  background-color: #061b45;
  border: 1px solid #09295b;
  color: #AED6FF;
} */

/* .el-form-item>>>.el-form-item__label {
  font-size: 12px;
  color: #AED6FF;
} */

/* .custom-input ::placeholder {
  color: #AED6FF;
} */

/* .custom-button {
  background: #176ad2;
  border:1px solid #176ad2 ;
} */

.searchContent {
  margin: 11px 25px 16px 26px;
}

.demo-ruleForm .el-form-item {
  width: 100%;
}

.demo-ruleForm {
  width: 100%;
}

.search {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 14px 26px 23px 20px;
  background: #061C45;
}

.custom-pager-text {
  margin-right: 5px;
}

.custom-page-input {
  width: 60px;
  margin-right: 5px;
}

.custom-page-button {
  padding: 5px 10px;
}


.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 11px;
}

.btn {
  width: 60px;
  height: 26px;
  line-height: 26px;
  text-align: center;
  background: rgba(255, 255, 255, 0);
  border: 1px solid #0b2653;
  border-radius: 2px;
  color: #92b5da;

}

.btn:hover {
  color: #ffffff;
}

.el-pagination>>>.btn-prev,
.el-pagination>>>.btn-next {
  background: rgba(255, 255, 255, 0);
  color: #d7d7d7;
  border-radius: 2px;
  border: 1px solid #0b2653;
  margin-right: 5px;
}

.el-pagination>>>.el-pager li {
  background: rgba(255, 255, 255, 0);
  color: #d7d7d7;
  border-radius: 2px;
  border: 1px solid #0b2653;
  margin-right: 5px;
}

.el-pagination>>>.el-pager .active {
  background: #176AD2;
  color: #d7d7d7;
  border-radius: 2px;
  border: 1px solid #0b2653;
  margin-right: 5px;
}

::v-deep .el-input__inner {
  background: rgba(255, 255, 255, 0);
  color: #d7d7d7;
  border-radius: 2px;
  border: 1px solid #0b2653;

}

.endBtn {
  margin-left: -15px;
  margin-right: 10px;
}

.paginationEnd {
  display: flex;
  justify-content: center;
  align-items: center;
}

.paginationEndIpt {
  width: 30px;
  margin: 0 7px;
  padding: 0;
  color: #92b5da;
  /* height: 16px; */
}

.paginationEndIpt>>>.el-input__inner {
  padding: 0 5px;
}

.confirm {
  margin-left: 10px;
  background: #176AD2;
  color: #d7d7d7;
}

/* 弹窗 */
::v-deep .el-dialog {
  background-color: #061d4a;
  font-size: 16px;
}

::v-deep .el-dialog__header {
  border-bottom: 1px solid #09295b;
}

::v-deep .el-dialog__title {
  font-size: 16px;
  color: #fff;
}

.dialog-footer {
  display: flex;
  justify-content: center;
  font-size: 16px;
  color: #000;
}

.dialogBtn {
  width: 100px;
  height: 35px;
  background: #2142A1;
  border: 0;
  color: #fff;
}

.newCenter {
  display: flex;
  margin-bottom: 10px;

}

.newCenterText {
  width: 100px;
  color: #C9D4D4;
}

::v-deep .el-textarea__inner {
  background-color: #061D4A;
  color: #AED6FF;
  border: 1px solid #09295b;
  font-family: "Microsoft YaHei", Arial, sans-serif;

}

/* .custom-input-textarea ::placeholder {
  color: red;
} */

.newCenterKey {
  width: 50px;
  color: #C9D4D4;
}

.deleteCenter {
  font-size: 16px;
  color: #EBEDF1;
}

::v-deep .el-range-input {
  background: #061C45;
  color: #AED6FF;
}

::v-deep .el-range-separator {
  color: #AED6FF;
}

.focus-message {
  background-color: white !important;
  border: none !important;
  font-size: 16px;
}

.focus-message>>>.el-icon-info::before {
  color: white !important;
}

.focus-message>>>.el-message__content {
  color: black !important;
}

::v-deep .el-textarea .el-input__count {
  color: #AED6FF;
  background: #061D4A;
}


/* .newCenterName{
  width: 100px;
  color: #C9D4D4;
} */
</style>
<style>
.custom-tooltip {
  max-width: 600px;
  /* 设置最大宽度 */
  line-height: 20px;
}</style>